<template>
    <div>
        <LoaderDefault v-if="this.isLoading" />
        <div v-else>
            <DefaultHeader2 :routeBack="'/Tenders'" :titleHeader="'Formulario de registro'" />
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">


                    <section v-if="!isLoading" class="content-form">
                        <div class="content-inputs" id="content-inputs">
                            <label for="number_process" id="number-pocess-label">Número de proceso *</label>
                            <input v-model="dataTender.number_proccess" class="input" name="number_process" type="text"
                                placeholder="Número de proceso" id="number_process" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="type_process">Tipo de proceso *</label>
                            <input v-model="dataTender.type_proccess" class="input" name="type_process" type="text"
                                placeholder="Tipo de proceso" id="type_process" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="status">Estado *</label>
                            <input v-model="dataTender.status" class="input" name="status" type="text" placeholder="Estado"
                                id="status" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="entity">
                                Entidad *
                            </label>
                            <input v-model="dataTender.entity" class="input" name="entity" type="text" placeholder="Entidad"
                                id="entity" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="type_object">Tipo objeto *</label>
                            <input id="type_object" v-model="dataTender.typeObject" class="input" name="type_object"
                                type="text" placeholder="Tipo objeto" required>
                        </div>
                        <div class="content-inputs" id="content-inputs">
                            <label for="cuantia">Cuantia *</label>
                            <input id="cuantia" v-model="dataTender.cuantia" class="input" name="cuantia" type="text"
                                placeholder="Cuantia" required>
                        </div>

                        <div class="content-inputs" id="content-inputs">
                            <label for="date_licitacion">Fecha *</label>
                            <input id="date_licitacion" v-model="dataTender.date" class="input" name="date_licitacion"
                                type="date" placeholder="fecha" required>
                        </div>

                    </section>
                    <hr>
                    <!-- section-anexos -->
                    <section v-if="!isLoading" class="sec-anexos" id="sec-anexos">
                        <div class="header-sec-anexos">
                            <h1 class="title-section-anexos">Subir anexos de las Invitaciones Publicas
</h1>
                            <i class="fa-solid fa-circle-info"></i>
                        </div>

                        <div class="content-list-items-add">
                            <div v-for="(item, index) in listItemAnexo" :key="index">
                                <div class="content-item">
                                    <h2 class="name-item-added">{{ item.name }}</h2>
                                    <i class="fa-sharp fa-solid fa-file-pdf icon-pdf"></i>
                                    <i class="el-icon-close text-danger icon-added" @click="showConfirmation(item)"></i>
                                </div>
                            </div>
                        </div>
                        <div class="content-form">
                            <div class="content-inputs" id="content-inputs">
                                <label class="label" id="label" for="name">Nombre *</label>
                                <input id="name" v-model="dataItemAnexo.name" class="input input-anexo" name="name"
                                    type="text" placeholder="Nombre" required>
                            </div>
                            <div class="content-inputs" id="content-inputs">
                                <label class="label" id="label" for="description">Descripción *</label>
                                <input id="description" v-model="dataItemAnexo.description" class="input input-anexo"
                                    name="description" type="text" placeholder="Descripción" required>
                            </div>

                            <div class="content-inputs" id="content-inputs">
                                <label class="label" id="label" for="date_publication">Fecha de publicación *</label>
                                <input id="date_publication" v-model="dataItemAnexo.datePublication"
                                    class="input input-anexo" name="date_publication" type="date"
                                    placeholder="Feca de publicación" required>
                            </div>

                            <div class="div-select-custom">
                                <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()" name="file-5"
                                    class="inputfile inputfile-5 input-anexo" required />
                                <label id="label" for="file-5" class="label-file label">
                                    <figure>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20"
                                            height="17" viewBox="0 0 20 17">
                                            <path
                                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                            </path>
                                        </svg>
                                    </figure>
                                </label>
                                <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar archivo *</h6>
                            </div>
                        </div>
                        <button class="btn-save-item" @click="addItemAnexoToArray()">Guardar anexo</button>
                    </section>
                    <hr>

                    <button v-if="!isLoading" class="btn-save-data" @click="saveTender()">Guardar Invitaciones Publicas
</button>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import { db, storage } from '../../../../firebaseConfig';
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';

export default {

    components: {
        DefaultHeader2,
        LoaderDefault,
    },
    data() {
        return {
            listItemAnexo: [],

            isLoading: false,
            selectedFile: File,

            dataItemAnexo: {
                name: '',
                description: '',
                datePublication: '',
                urlPdf: 'url',
                file: ''
            },

            dataTender: {
                _id: '_id',
                number_proccess: '',
                type_proccess: '',
                status: '',
                entity: '',
                typeObject: '',
                cuantia: '',
                date: '',
                anexos: []
            },

        };
    },
    mounted() {

    },

    methods: {
        navToIndexTender() { this.$router.push('Tenders') },

        addItemAnexoToArray() {
            this.dataItemAnexo.file = this.selectedFile
            this.listItemAnexo.push(this.dataItemAnexo)
            this.dataItemAnexo = {}
        },

        removeItemAnexoToArray(itemAnexo) {
            const index = this.listItemAnexo.indexOf(itemAnexo);
            if (index > -1) this.listItemAnexo.splice(index, 1);
        },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                // this.selectedFile = File;
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        },

        async saveTender() {
            if (this.validaFormFirtForm()) {
                if (this.listItemAnexo.length > 0) {
                    this.isLoading = true
                    try {
                        const collectionRef = db.collection('tenders');
                        const docRef = await collectionRef.add(this.dataTender);
                        const docId = docRef.id;
                        const storageRef = storage.ref();
                        const anexosPromises = this.listItemAnexo.map(async (anexo) => {
                            const pdfRef = storageRef
                                .child('pdfsAnexosTender')
                                .child(docId)
                                .child(anexo.name);

                            await pdfRef.put(anexo.file);
                            const pdfUrl = await pdfRef.getDownloadURL();
                            const anexoData = {
                                _idName: anexo.name,
                                name: anexo.name,
                                description: anexo.description,
                                datePublication: anexo.datePublication,
                                urlPdf: pdfUrl
                            };

                            return anexoData;
                        });

                        this.listItemAnexo = await Promise.all(anexosPromises);

                        await collectionRef.doc(docId).update({
                            _id: docId,
                            anexos: this.listItemAnexo
                        });
                        this.$swal
                            .fire({
                                title: 'Licitación',
                                text: 'Nueva licitación creada con exito',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Aceptar',
                                confirmButtonColor: "#45A825",
                            })
                            .then((result) => {
                                if (result.value) this.navToIndexTender()
                                else this.navToIndexTender()
                            });
                        this.isLoading = false
                    } catch (error) {                        
                        throw error;
                    }
                } else
                    this.$swal
                        .fire({
                            title: 'Información importante',
                            text: 'Para lograr el almacenamiento correcto de una nueva licitación es necesario ' +
                                'agregar minimo un anexo',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                            timer: 5000
                        })
            }
        },

        showConfirmation(itemAnexo) {
            this.$swal
                .fire({
                    title: 'Eliminar anexo',
                    text: '¿Estás seguro de realizar esta acción?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.removeItemAnexoToArray(itemAnexo);
                });
        },

        validaFormFirtForm() {
            const inputs = document.getElementsByTagName('input');
            const label = document.getElementsByTagName('label');

            for (let i = 0; i < inputs.length - 4; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    label[i].style.color = ''
                }
            }
            return true;
        },

        validaFormSecondForm() {
            const inputs = document.querySelectorAll('.input-anexo');
            const labels = document.querySelectorAll('.label');
            const elementSelectPdf = document.getElementById('name-pdf-selected')

            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i].hasAttribute('required') && inputs[i].value.trim() === '') {
                    const label = document.querySelector(`label[for="${inputs[i].id}"]`);
                    label.style.color = 'red';
                    elementSelectPdf.style.color = "red"
                    label.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    return false;
                } else {
                    inputs[i].style.backgroundColor = '';
                    labels[i].style.color = ''
                    elementSelectPdf.style.color = ''
                }
            }
            return true;
        }
    },
}
</script>